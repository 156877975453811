export const INITIALIZE = "INITIALIZE";
export const LOGIN = "LOGIN";
export const AUTHENTICATED = "AUTHENTICATED";
export const LOGOUT = "LOGOUT";
export const LOGIN_RESUME = "LOGIN_RESUME";
export const LOGIN_OIDC = "LOGIN_OIDC";
export const LOGIN_DISCOURSE = "LOGIN_DISCOURSE";

export const NEED_COMMON = "NEED_COMMON";
export const NEED_PROJECTS = "NEED_PROJECTS";
export const NEED_STATIONS = "NEED_STATIONS";
export const NEED_PROJECT = "NEED_PROJECT";
export const NEED_PROJECTS_FOR_STATION = "NEED_PROJECTS_FOR_STATION";
export const NEED_STATION = "NEED_STATION";
export const UPDATE_STATION = "UPDATE_STATION";
export const CLEAR_STATION = "CLEAR_STATION";
export const UPDATE_STATION_MODULE = "UPDATE_STATION_MODULE";

export const STATION_PROJECT_ADD = "STATION_PROJECT_ADD";
export const STATION_PROJECT_REMOVE = "STATION_PROJECT_REMOVE";

// User

export const REFRESH_CURRENT_USER = "REFRESH_CURRENT_USER";
export const UPLOAD_USER_PHOTO = "UPLOAD_USER_PHOTO";
export const UPDATE_USER_PROFILE = "UPDATE_USER_PROFILE";

// Projects

export const PROJECT_FOLLOW = "PROJECT_FOLLOW";
export const PROJECT_UNFOLLOW = "PROJECT_UNFOLLOW";
export const PROJECT_INVITE = "PROJECT_INVITE";
export const PROJECT_REMOVE = "PROJECT_REMOVE";
export const PROJECT_EDIT_ROLE = "PROJECT_EDIT_ROLE";
export const ACCEPT_PROJECT_INVITE = "ACCEPT_PROJECT_INVITE";
export const DECLINE_PROJECT_INVITE = "DECLINE_PROJECT_INVITE";
export const ACCEPT_PROJECT = "ACCEPT_PROJECT";
export const DECLINE_PROJECT = "DECLINE_PROJECT";
export const ADD_PROJECT = "ADD_PROJECT";
export const SAVE_PROJECT = "SAVE_PROJECT";
export const DELETE_PROJECT = "DELETE_PROJECT";

// Exports

export const NEED_EXPORTS = "NEED_EXPORTS";
export const BEGIN_EXPORT = "BEGIN_EXPORT";

export const NOTIFICATIONS_SEEN = "NOTIFICATIONS_SEEN";
export const NOTIFICATION_SEEN = "NOTIFICATION_SEEN";
export const NOTIFICATIONS_INITIALIZE = "NOTIFICATIONS_INITIALIZE";

// Notes

export const NEED_NOTES = "NEED_NOTES";
export const NEED_FIELD_NOTES = "NEED_FIELD_NOTES";
export const ADD_FIELD_NOTE = "ADD_FIELD_NOTE";
export const UPDATE_FIELD_NOTE = "UPDATE_FIELD_NOTE";
export const DELETE_FIELD_NOTE = "DELETE_FIELD_NOTE";

// Snackbar

export const SHOW_SNACKBAR = "SHOW_SNACKBAR";
export const HIDE_SNACKBAR = "HIDE_SNACKBAR";

// Discussion

export const SET_DISCUSSION_PERMISSIONS = "SET_DISCUSSION_PERMISSIONS";
export const NEED_DATA_EVENTS = "NEED_DATA_EVENTS";
export const NEW_DATA_EVENT = "NEW_DATA_EVENT";

// Busy

export const NEW_DIRTY_FIELD = "ADD_DIRTY_FIELD";
export const CLEAR_DIRTY_FIELD = "CLEAR_DIRTY_FIELD";
export const CLEAR_ALL_DIRTY_FIELDS = "CLEAR_ALL_DIRTY_FIELDS";
